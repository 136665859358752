<template>
  <div class=''>
    <component :is='portalOrDiv' :to='titleLocation'>
      <h1 class='text-2xl font-semibold pl-8 py-8'>리멤버 엑셀파일 업로드</h1>
    </component>
    <input class='block mb-6' @change='fileSelect($event)' type='file' accept='.xlsx, .xls, .csv'>
    <button v-if='file'
            class='border rounded-md text-sm text-gray-700 py-4 px-6 border-blue-300'
            @click='uploadFile'>
      Import Contacts <upload-icon class='inline-block h-4' style='margin-top: -2px;' />
    </button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { UploadIcon } from '@vue-hero-icons/outline'
export default {
  name: 'ContactsBatchUpload',
  components: {
    UploadIcon,
  },
  props: [
    'displayInModal'
  ],
  data () {
    return {
      file: null,
    }
  },
  computed: {
    portalOrDiv () {
      return (this.displayInModal) ? 'portal' : 'div'
    },
    titleLocation () {
      return (this.displayInModal) ? 'modal-header' : ''
    },
  },
  methods: {
    ...mapActions('contacts', [
      'uploadContactsFile',
    ]),
    uploadFile () {
      let formData = new FormData()
      formData.append('contact[file]', this.file, this.file.name)
      this.uploadContactsFile(formData).then(resp => {
        if (resp === 'fail') {
          this.$message.error('Upload Fail')
        } else {
          this.$message({
            message: 'Upload Success',
            type: 'success'
          })
        }
      })
    },
    fileSelect (event) {
      this.file = event.target.files[0]
    },
  },
}
</script>
