<template>
  <div class='relative w-1/2'>
    <v-select
      v-model='selectedIndustry'
      @input='updateSelectedIndustry'
      @search='searchIndustries'
      label='name'
      placeholder='코드 / 명칭'
      ref='industrySearch'
      :filterable='false'
      :close-on-select='true'
      :options='localIndustries'>
      <template v-slot:option='option'>
        <div class='px-2 py-2 flex flex-row justify-between items-center'>
          <div>{{ option.name }} <span class='inline-block opacity-75'>({{ option.code }})</span></div>
        </div>
      </template>
      <template v-slot:no-options>
        <div class='text-gray-500 py-3'>찾으시려는 코드 / 명칭을 입력하세요.</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import cloneDeep                            from 'lodash/cloneDeep'
import EventBus from '@/utils/event-bus'

export default {
  name: 'IndustrySearch',
  props: [
    'name',
    'code',
  ],
  data () {
    return {
      selectedIndustry: [
        { code: this.code,
          name: this.name }
      ],
      localIndustries: [
        { code: this.code,
          name: this.name }
      ],
    }
  },
  computed: {
    searchText () {
      return this.$refs.industrySearch.search
    },
  },
  methods: {
    ...mapActions('entities', [
      'getIndustries',
    ]),
    searchIndustries () {
      if (this.searchText) {
        this.getIndustries({search_text: this.searchText}).then(resp => {
          this.localIndustries = cloneDeep(resp)
        })
      }
    },
    updateSelectedIndustry () {
      if (this.selectedIndustry && this.selectedIndustry.code.length > 0) {
        this.$emit('update-selected-industry', this.selectedIndustry)
      }
    },
  },
  beforeDestroy () {
    EventBus.$off('modalClose')
  }
}
</script>
