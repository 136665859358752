<template>
  <div class=''>
    <portal :to='titleLocation'>
      <h1 class='pl-8 py-8 text-2xl font-semibold uppercase'>{{title}}</h1>
    </portal>
    <contact-edit-fields />
    <portal :to='controlLocation'>
      <div class='flex flex-row justify-start w-full bg-gray-100 pl-8 pr-8 py-4 text-sm shadow-lg'>
        <button class='border px-12 h-12 text-white rounded-md font-bold hover:shadow-md defaultStyle' :disabled='invalidContact' @click='submitUpdate'>{{buttonActionText}}</button>
        <button class='ml-2 px-8 h-12 border rounded-md border-transparent hover:border-gray-500' @click='cancelEditing'>취소</button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ContactEditFields from '@/components/contacts/ContactEditFields.vue'
export default {
  name: 'ContactEdit',
  components: {
    ContactEditFields,
  },
  props: [
    'displayInModal'
  ],
  computed: {
    ...mapFields('contacts', [
      'editingContact',
    ]),
    buttonActionText () {
      return this.isNewContact ? '추가' : '수정'
    },
    invalidContact () {
      return !!((this.editingContact.fax_number && this.notNumRegex.test(this.editingContact.fax_number)) ||
        (this.editingContact.phone_number && this.notNumRegex.test(this.editingContact.phone_number)) ||
        (this.editingContact.office_phone_number && this.notNumRegex.test(this.editingContact.office_phone_number)))
    },
    isNewContact () {
      return !this.editingContact.id
    },
    controlLocation () {
      return this.displayInModal ? 'modal-footer' : 'sidepanel-footer'
    },
    notNumRegex () {
      return /([^0-9-])/g
    },
    titleLocation () {
      return this.displayInModal ? 'modal-header' : 'sidepanel-header'
    },
    title () {
      return this.isNewContact ? 'New Contact' : `수정: ${this.editingContact.contact_name}`
    }
  },
  methods: {
    ...mapActions('contacts', [
      'addEditingContact',
      'updateEditingContact',
      'cancelEditing',
      'updateMyContact',
    ]),
    submitUpdate () {
      if (this.isNewContact) {
        this.addEditingContact().then(resp => {
          this.$emit('updated-contact', resp)
        })
      } else {
        this.updateEditingContact().then(resp => {
          this.$emit('updated-contact', resp)
        })
      }
    },
    updateSelectedEntity (entity) {
      this.editingContact.entity_name = entity.entityName
    },
  },
}
</script>

<style lang='scss' scoped>
  button:disabled, button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
  .defaultStyle {
    background-color: #223645;
  }
</style>
