<template>
  <div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>회사이름</label>
      <input class='form-input w-1/4' v-model='drilldownEntityName'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>기업코드</label>
      <input class='form-input w-1/4' v-model='drilldownEntityCompanyCode'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='w-1/6'>법인구분</label>
      <select v-model='drilldownEntityCompanyType' class='form-select'>
        <option label='국내설립법인'>국내설립법인</option>
        <option label='해외설립법인'>해외설립법인</option>
        <option label='외국법인'>외국법인</option>
      </select>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>소재지국가</label>
      <input class='form-input w-1/4' v-model='drilldownEntityCountry'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>소재지(주소)</label>
      <input class='form-input w-5/6' v-model='drilldownEntityAddress'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>설립일</label>
      <el-date-picker
        v-model='drilldownEntityFoundationDate'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='Foundation Date'>
      </el-date-picker>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>대표자성명</label>
      <input class='form-input w-1/4' v-model='drilldownEntityCeoName'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>대표자지분율(%)</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='drilldownEntityCeoPercentage'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>사업자등록번호</label>
      <input class='form-input w-1/4' v-model='drilldownEntityBusinessLicenseNumber' placeholder='000-00-00000' pattern='\d{3}-\d{2}-\d{5}'>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>법인번호</label>
      <input class='form-input w-1/4' v-model='drilldownEntityCompanyNumber'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>주요사업</label>
      <input class='form-input w-1/4' v-model='drilldownEntityMajorBusiness'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>주요제품</label>
      <input class='form-input w-1/4' v-model='drilldownEntityMajorItem'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>업종 검색</label>
      <industry-search :name='drilldownEntityBusinessType' :code='drilldownEntityBusinessCode' @update-selected-industry='updateEntityIndustry'/>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>뉴딜코드 검색</label>
      <venture-industry-search :newdealCode='drilldownEntityNewdealCode' :newdealName='drilldownEntityNewdealName' @update-selected-venture-industry='updateEntityVentureIndustry'/>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>기업규모</label>
      <input class='form-input w-1/4' v-model='drilldownEntityBusinessScale'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>섹터 대분류</label>
      <select v-model='drilldownEntitySectorLarge' class='form-select'>
        <option v-for='sector in sectorCodes' :key='sector.code_name' :value='sector.code_name'>
          {{ sector.code_name }}
        </option>
      </select>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>상장주관사</label>
      <input class='form-input w-1/4' v-model='drilldownEntityUnderwriter'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>상장진행현황</label>
      <input class='form-input w-1/4' v-model='drilldownEntityUnderwritingStatus'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>상장시장</label>
      <select v-model='drilldownEntityMarket' class='form-select'>
        <option value='유가시장'>유가시장</option>
        <option value='코스닥시장'>코스닥시장</option>
        <option value='국외유가증권시장'>국외유가증권시장</option>
        <option value='비상장-벤처'>비상장-벤처</option>
        <option value='비상장-Pre IPO'>비상장-Pre IPO</option>
        <option value='매매제한(상장은 유지)'>매매제한(상장은 유지)</option>
        <option value='기타(매매제한 등)'>기타(매매제한 등)</option>
      </select>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>총발행주식수</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='drilldownEntityTotalIssueQuantity'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>자기주식수</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='drilldownEntitySelfOwnedQuantity'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>유통주식수</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='drilldownEntityOutstandingStockQuantity'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>임직원수</label>
      <input class='form-input w-1/4 text-right' type='number' v-model='drilldownEntityStaffNumber'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/6'>임직원수 기준일</label>
      <el-date-picker
        v-model='drilldownEntityStaffDate'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='Staff Date'>
      </el-date-picker>
    </div>

    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='w-1/6'>벤처기업구분</label>
      <select v-model='drilldownEntityVentureType' class='form-select'>
        <option value='벤처기업'>벤처기업</option>
        <option value='벤처기업 해제 후 7년 이내'>벤처기업 해제 후 7년 이내</option>
        <option value='해당사항없음'>해당사항없음</option>
      </select>

      <label class='inline-block w-1/6 ml-4'>벤처인증기간</label>
      <el-date-picker
        v-model='drilldownEntityVentureDate'
        value-format='yyyy-MM-dd'
        type='date'
        placeholder='~벤처인증기간'>
      </el-date-picker>
    </div>

    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='w-1/6'>가치주/성장주</label>
      <select v-model='drilldownEntityEstimationType' class='form-select'>
        <option value='가치주'>가치주</option>
        <option value='성장주'>성장주</option>
      </select>
    </div>

    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='inline-block w-1/4'>
        <input type='checkbox' class='form-checkbox' v-model='drilldownEntityIsUniformStocks'>
        통일주권발행 여부
      </label>
      <label class='inline-block w-1/4 ml-4'>
        <input type='checkbox' class='form-checkbox' v-model='drilldownEntityIsIpo'>
        기업공개 여부
      </label>
    </div>

    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>대표자 경력</label>
      <textarea
        class='text-textarea w-5/6 border rounded-md pl-4 py-1 pr-32 h-32 leading-tight'
        v-model='drilldownEntityCeoCareer'
        placeholder='대표자 경력 입력'>
      </textarea>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>주요 주주</label>
      <textarea
        class='text-textarea w-5/6 border rounded-md pl-4 py-1 pr-32 h-32 leading-tight'
        v-model='drilldownEntityMajorStockholders'
        placeholder='주요 주주 입력'>
      </textarea>
    </div>
    <div class='mb-4 flex leading-10'>
      <label class='inline-block w-1/6'>참고사항</label>
      <textarea
        class='text-textarea w-5/6 border rounded-md pl-4 py-1 pr-32 h-32 leading-tight'
        v-model='drilldownEntityEntityComment' placeholder='참고사항 입력'>
      </textarea>
    </div>

    <entity-attached-file />
  </div>
</template>

<script>
import { mapFields }          from 'vuex-map-fields'
import { mapState }           from 'vuex'
import EntityAttachedFile     from '@/views/entities/EntityAttachedFile.vue'
import IndustrySearch         from '@/views/entities/IndustrySearch.vue'
import VentureIndustrySearch  from '@/views/entities/VentureIndustrySearch.vue'

export default {
  name: 'EntityDataEditInvestmentModeFields',
  data () {
    return {
    }
  },
  components: {
    EntityAttachedFile,
    IndustrySearch,
    VentureIndustrySearch,
  },
  computed: {
    ...mapFields('entities', {
      drilldownEntityName: 'drilldownEntity.entity_name',
      drilldownEntityAddress: 'drilldownEntity.address',
      drilldownEntityCountry: 'drilldownEntity.country',
      drilldownEntityCeoName: 'drilldownEntity.ceo_name',
      drilldownEntityCeoPercentage: 'drilldownEntity.ceo_percentage',
      drilldownEntityFoundationDate: 'drilldownEntity.foundation_date',
      drilldownEntityLicenseNumber: 'drilldownEntity.business_license_number',
      drilldownEntityCompanyNumber: 'drilldownEntity.company_number',
      drilldownEntityMajorBusiness: 'drilldownEntity.major_business',
      drilldownEntityMajorItem: 'drilldownEntity.major_item',
      drilldownEntityMarket: 'drilldownEntity.market',
      drilldownEntityBusinessType: 'drilldownEntity.business_type',
      drilldownEntityBusinessCode: 'drilldownEntity.business_code',
      drilldownEntityBusinessScale: 'drilldownEntity.business_scale',
      drilldownEntityTotalIssueQuantity: 'drilldownEntity.total_issue_quantity',
      drilldownEntityOutstandingStockQuantity: 'drilldownEntity.outstanding_stock_quantity',
      drilldownEntityEntityComment: 'drilldownEntity.entity_comment',
      drilldownEntityIsUniforStocks: 'drilldownEntity.is_uniform_stocks',
      drilldownEntityIsVenture: 'drilldownEntity.is_venture',
      drilldownEntityIsIpo: 'drilldownEntity.is_ipo',
      drilldownEntityIsSurtax: 'drilldownEntity.is_surtax',
      drilldownEntityCeoCareer: 'drilldownEntity.ceo_career',
      drilldownEntityBusinessLicenseNumber: 'drilldownEntity.business_license_number',
      drilldownEntityIsUniformStocks: 'drilldownEntity.is_uniform_stocks',
      drilldownEntityStaffDate: 'drilldownEntity.staff_date',
      drilldownEntityStaffNumber: 'drilldownEntity.staff_number',
      drilldownEntityAttachedFile: 'drilldownEntity.attached_file',
      drilldownEntitySelfOwnedQuantity: 'drilldownEntity.self_owned_quantity',
      drilldownEntityVentureType: 'drilldownEntity.venture_type',
      drilldownEntityEstimationType: 'drilldownEntity.estimation_type',
      drilldownEntityVentureDate: 'drilldownEntity.venture_date',
      drilldownEntityNewdealCode: 'drilldownEntity.newdeal_code',
      drilldownEntityNewdealName: 'drilldownEntity.newdeal_name',
      drilldownEntityMajorStockholders: 'drilldownEntity.major_stockholders',
      drilldownEntityCompanyCode: 'drilldownEntity.company_code',
      drilldownEntitySectorLarge: 'drilldownEntity.sector_large',
      drilldownEntitySectorMedium: 'drilldownEntity.sector_medium',
      drilldownEntitySectorSmall: 'drilldownEntity.sector_small',
      drilldownEntityUnderwriter: 'drilldownEntity.underwriter',
      drilldownEntityUnderwritingStatus: 'drilldownEntity.underwriting_status',
      drilldownEntityCompanyType: 'drilldownEntity.company_type',
    }),
    ...mapState('entities', [
      'sectorCodes',
    ]),
  },
  methods: {
    updateEntityIndustry (industry) {
      this.drilldownEntityBusinessType = industry.name
      this.drilldownEntityBusinessCode = industry.code
    },
    updateEntityVentureIndustry (ventureIndustry) {
      this.drilldownEntityNewdealCode = ventureIndustry.item_code
      this.drilldownEntityNewdealName = ventureIndustry.item_name
    },
  },
}
</script>

<style lang='scss' scoped>
.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}
</style>
