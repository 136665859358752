<template>
  <div class='mb-4 flex leading-10'>
    <label class='inline-block w-1/6'>청부파일</label>
    <div v-if='hasFile || hasPendingFile' class='overflow-wrap leading-10'>
      <a :href='fileURL'
        download
        target='_blank'
        class='inline-block bg-gray-100 py-1 px-1 hover:underline hover:bg-transparent h-12 leading-10'>{{ drilldownEntityFileName }}</a>
    </div>
    <div>
      <label class='inline-block bg-gray-100 py-1 px-4 uppercase text-blue-800 flex-grow h-12 leading-10 cursor-pointer hover:bg-gray-200'>
        {{ uploadText }}
        <input @change='fileSelect' type='file' ref='AttachedFile' class='bg-pink-200 hidden'>
      </label>
    </div>
  </div>
</template>

<script>
import { mapFields }  from 'vuex-map-fields'
import { mapActions } from 'vuex'

export default {
  name: 'EntityAttachedFile',
  data () {
    return {
      fileInput: null,
    }
  },
  computed: {
    ...mapFields('entities', {
      drilldownEntity: 'drilldownEntity',
      drilldownEntityFileName: 'drilldownEntity.file_name',
      drilldownEntityFileUrl: 'drilldownEntity.file_url',
    }),
    uploadText () {
      if (this.hasFile) {
        return '[ Replace file ]'
      } else {
        return '[ Upload new file ]'
      }
    },
    fileURL () {
      return decodeURI(this.drilldownEntityFileUrl) || ''
    },
    hasFile () {
      return (this.drilldownEntityFileUrl !== null && this.drilldownEntityFileUrl !== '')
    },
    hasPendingFile () {
      return (this.fileInput !== null)
    },
  },
  methods: {
    ...mapActions('entities', [
      'uploadEntity',
    ]),
    fileSelect () {
      this.fileInput = this.$refs.AttachedFile.files[0]
      this.uploadFiles()
    },
    uploadFiles () {
      var fileInfo =  {
        id: this.drilldownEntity.id, 
        attachedFile: this.fileInput
      }
      this.uploadEntity(fileInfo).then((resp) => {
        this.drilldownEntityFileUrl = resp.file_url
        this.drilldownEntityFileName = resp.file_name
        this.fileInput = null
      })
    },
  }
}
</script>
