<template>
  <tr class='cursor-pointer text-gray-700 hover:bg-gray-200'
    :class='selectedContactRowClass'
    @click='contactDrilldownOpen(contact)'>
    <td class='px-2 py-2'>{{contact.contact_name}}</td>
    <td class='px-2 py-2'>
    <button
      @click.stop='updateShareable'
      class='py-2 pl-2 pr-5 border rounded-md border-transparent hover:border-blue-400 hover:bg-blue-100'>
      <contact-share-status :shareable='contact.shareable'/>
    </button>
    </td>
    <td class='px-2 py-2 border-l'>{{contact.entity_name}}</td>
    <td class='px-2 py-2'>{{contact.department}}</td>
    <td class='px-2 py-2'>{{contact.title}}</td>
    <td class='px-2 py-2 border-l whitespace-no-wrap'>{{contact.phone_number}}</td>
    <td class='px-2 py-2 whitespace-no-wrap'>{{contact.office_phone_number}}</td>
    <td class='px-2 py-2 whitespace-no-wrap'>{{contact.fax_number}}</td>
    <td class='px-2 py-2 whitespace-no-wrap'>{{contact.email}}</td>
    <td class='px-2 py-2'>{{contact.address}}</td>
  </tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ContactShareStatus  from '@/components/contacts/ContactShareStatus.vue'
import cloneDeep      from 'lodash/cloneDeep'

export default {
  name: 'ContactRow',
  components: {
    ContactShareStatus,

  },
  props: [
    'contact'
  ],
  data () {
    return {
      updatingContact: [],
    }
  },
  computed: {
    ...mapGetters('contacts', [
    'drilldownContactId'
    ]),

    selectedContactRowClass () {
      return (this.drilldownContactId === this.contact.id) ? 'bg-blue-100 font-semibold' : ''
    }
  },
  methods: {
    ...mapActions('contacts', [
      'contactDrilldownOpen',
      'updateContact',
    ]),
    updateShareable () {
      this.updatingContact = cloneDeep(this.contact)
      this.updatingContact.shareable = !this.updatingContact.shareable
      this.updateContact(this.updatingContact)
    }
  },
}
</script>
