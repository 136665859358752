<template>
  <div class='px-2 lg:px-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>Contacts</h1>
      </div>
    </portal>
    <div class='flex flex-row my-6'>
      <button @click='addContact'
        class='border p-3 rounded-l-md border-gray-50 text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md flex flex-row justify-center items-center'>
        <plus-circle-icon class='h-4'/>
      </button>
      <button @click='uploadContacts'
        class='border p-3 rounded-r-md border-gray-50 text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md flex flex-row justify-center items-center'>
        <cloud-upload-icon class='h-4'/>
      </button>
      <input
        v-model='searchText'
        ref='searchContacts'
        type='search'
        placeholder='이름, 회사명 검색'
        class='border w-64 ml-5 mt-1 h-10 pl-5 focus:shadow'
        style='border-radius: 1.5rem;'>
    </div>
    <div class='overflow-auto'>
      <table class='text-sm'>
        <thead>
          <tr class='text-left uppercase tracking-wide border-b'>
            <th class='px-2 py-2 text-left whitespace-no-wrap '>이름</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap '>개인/공유 여부</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap border-l'>회사</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap '>부서</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap '>직책</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap border-l'>전화번호</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap'>사무실 번호</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap'>팩스 번호</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap '>이메일</th>
            <th class='px-2 py-2 text-left whitespace-no-wrap '>주소</th>
          </tr>
        </thead>
        <tbody>
          <contact-row
            v-for='(contact,index) in contacts'
            :key='`contact-list-${contact.id}-${index}`'
            :contact='contact'
            class='border-t' />
        </tbody>
      </table>
    </div>
    <portal to='modals'>
      <contact-edit v-if='showAddContacts'
        @updated-contact='doneEditing'
        :display-in-modal='true' />
      <contacts-batch-upload v-if='showUploadContacts'
       :display-in-modal='true' />
    </portal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { CloudUploadIcon, PlusCircleIcon } from '@vue-hero-icons/outline'
import ContactEdit         from '@/components/contacts/ContactEdit.vue'
import ContactRow          from '@/views/contacts/ContactRow.vue'
import ContactsBatchUpload from '@/views/contacts/ContactsBatchUpload.vue'
import EventBus            from '@/utils/event-bus'
import debounce            from 'debounce'


export default {
  name: 'Contacts',
  components: {
    ContactsBatchUpload,
    ContactEdit,
    ContactRow,
    PlusCircleIcon,
    CloudUploadIcon,
  },
  data () {
    return {
      showAddContacts:    false,
      showUploadContacts: false,
      searchText: '',
    }
  },
  watch: {
    showAddContacts: {
      handler: function (newVal) {
        if (!newVal) {
          this.$store.dispatch('modalClose')
        }
      },
      immediate: true,
    },
    'searchText': {
      handler: function (newVal) {
        if (newVal.trim() !== '') {
          this.searchContacts(newVal)
        }
      },
      immediate: false
    },
  },
  computed: {
    ...mapState('contacts', [
      'contacts',
    ]),
  },
  methods: {
    ...mapActions('contacts', [
      'getContacts',
      'contactDrilldownOpen',
      'searchContacts',
      'cancelEditing',
    ]),
    addContact () {
      this.showAddContacts = true
      this.$store.dispatch('modalOpen')
    },
    doneEditing () {
      this.showAddContacts = false
      this.cancelEditing()
    },
    uploadContacts () {
      this.showUploadContacts = true
      this.$store.dispatch('modalOpen')
    },
  },
  created () {
    this.searchContacts = debounce(this.searchContacts, 500)
  },
  mounted () {
    this.getContacts()
    EventBus.$on('modalClose', () => {
      this.showAddContacts = false
      this.showUploadContacts = false
    })
  },
  beforeDestroy () {
    EventBus.$off('modalClose')
  },
}
</script>
