<template>
  <div class='relative w-1/2'>
    <v-select
      v-model='selectedVentureIndustry'
      @input='updateSelectedVentureIndustry'
      @search='searchVentureIndustries'
      label='item_name'
      placeholder='코드 / 명칭'
      ref='ventureIndustrySearch'
      :filterable='false'
      :close-on-select='true'
      :options='localVentureIndustries'>
      <template v-slot:option='option'>
        <div class='px-2 py-2 flex flex-row justify-between items-center'>
          <div>{{ option.item_name }} <span class='inline-block opacity-75'>({{ option.item_code }})</span></div>
        </div>
      </template>
      <template v-slot:no-options>
        <div class='text-gray-500 py-3'>찾으시려는 코드 / 명칭을 입력하세요.</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import cloneDeep                            from 'lodash/cloneDeep'
import EventBus from '@/utils/event-bus'

export default {
  name: 'VentureIndustrySearch',
  props: ['newdealCode', 'newdealName'],
  data () {
    return {
      selectedVentureIndustry: [
        { item_code: this.newdealCode,
          item_name: this.newdealName }
      ],
      localVentureIndustries: [
        { item_code: this.newdealCode,
          item_name: this.newdealName }
      ],
    }
  },
  computed: {
    searchText () {
      return this.$refs.ventureIndustrySearch.search
    },
  },
  methods: {
    ...mapActions('entities', [
      'getVentureIndustries',
    ]),
    searchVentureIndustries () {
      if (this.searchText) {
        this.getVentureIndustries({search_text: this.searchText}).then(resp => {
          this.localVentureIndustries = cloneDeep(resp)
        })
      }
    },
    updateSelectedVentureIndustry () {
      if (this.selectedVentureIndustry && this.selectedVentureIndustry.item_code.length > 0) {
        this.$emit('update-selected-venture-industry', this.selectedVentureIndustry)
      }
    },
  },
  beforeDestroy () {
    EventBus.$off('modalClose')
  },
}
</script>
